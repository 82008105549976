import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";

import { withContext } from "~context";
import { Button } from "~components/utils/button";
import { PageLayout } from "~components/templates/page-layout";
import * as styles from "./index.module.scss";

type HeaderProps = {
  title: string;
  date: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { title, date } = props;
  const dateFormatted = dayjs(date).format("YYYY.MM.DD");
  return (
    <div className={styles.Header}>
      <div className={styles.Header_Inner}>
        <p>{dateFormatted}</p>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

type Props = {
  data: {
    site: {
      siteMetadata: {
        title: string;
        author: string;
      };
    };
    contentfulNews: GatsbyContentfulNews;
  };
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const post = data.contentfulNews;
  const thumbnail = post.thumbnail?.file?.url;

  useEffect(() => {
    const hatenaHelper = document.createElement("script");
    hatenaHelper.src = "https://b.st-hatena.com/js/bookmark_button.js";
    document.body.appendChild(hatenaHelper);

    const twitterHelper = document.createElement("script");
    twitterHelper.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(twitterHelper);

    if (document.getElementById("script-facebook") === null) {
      const facebookHelper = document.createElement("script");
      facebookHelper.src =
        "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v10.0";
      facebookHelper.id = "script-facebook";
      document.body.appendChild(facebookHelper);
    } else {
      (window as any).FB.XFBML.parse();
    }
  }, []);

  return (
    <PageLayout
      paddingPattern="fit-header"
      meta={{
        title: post.title || undefined,
        description: post.description || undefined,
        image: thumbnail ? `https:${thumbnail}` : undefined,
      }}
    >
      <div className={styles.News}>
        {post.title && <Header title={post.title} date={post.date} />}
        {post.body?.body != undefined && (
          <div className={styles.News_Content}>
            <ReactMarkdown>{post.body.body}</ReactMarkdown>
          </div>
        )}
        <div className={styles.News_ShareButtonWrapper}>
          <li className={styles.News_ShareButton}>
            <div className={styles.News_ShareButton__Twitter}>
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
              >
                Tweet
              </a>
            </div>
          </li>
          <li className={styles.News_ShareButton}>
            <div
              className="fb-share-button"
              data-href={`https://flatt.tech/news/${post.slug}`}
              data-layout="button_count"
              data-size="small"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fflatt.tech%2Fnews&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore"
                rel="noreferrer"
              >
                シェア
              </a>
            </div>
          </li>
          <li className={styles.News_ShareButton}>
            <a
              href="https://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="basic-label-counter"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
              data-show-count="false"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                width="20"
                height="20"
              />
            </a>
          </li>
        </div>
        <Link to="/news">
          <div className={styles.News_BackTo}>
            <Button buttonTheme="Secondary" buttonSize="Large">
              ニュース一覧に戻る
            </Button>
          </div>
        </Link>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);

export const query = graphql`
  query PostPageComponent($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      id
      createdAt
      date
      slug
      title
      description
      thumbnail {
        file {
          url
        }
      }
      body {
        body
      }
    }
  }
`;
